<template>
    <div class="page-content">
        <page-breadcrumb v-if="item" title="Report Detail" class="mb-2" :items="breadcrumbItems" />
        <section class="mb-2">
        <data-table-ssr v-if="item" id="group_list" ref="group_list"
            :columns="fields" :get-list-fn="getExamRecords"
        />
        </section>
        <action-buttons
            back-button
        />
    </div>
</template>
<script>
import service from '../service';

const fields = [
    { label: 'UserId', field: '_id'},
    { label: 'Email', field: 'email'},
    { label: 'Market', field: 'language' },
    { label: 'Country', field: 'country' },
    { label: 'Content Rating', field: 'content_score'},
    { label: 'Experience Rating', field: 'experience_score'},
    { label: 'Host Rating', field: 'host_score'}, 
    { label: 'Comment', field: 'comment'}, 
    { label: 'Level user selected', field: 'selected_level', input_type: "select", options: [
        {text: "Pre- A1", value: 1},
        {text: "A1", value: 2},
        {text: "A2.1", value: 3},
        {text: "A2.2", value: 4},
        {text: "B1", value: 5},
        {text: "B2", value: 6},
    ] },
    { label: 'Entry Test level', field: 'entry_level' },
    // { label: 'Entry Test - Vocab', field: 'vocab'},
    // { label: 'Entry Test - Listening', field: 'listening'},
    // { label: 'Entry Test - Interaction', field: 'interaction'},
    // { label: 'Entry Test - Spoken', field: 'pronunciation'},
];
export default {
    data(){
        return{
            item: null,
            fields,
            exam_records: null
        }
    },
    computed: {
        itemId() {
            return this.$route.params.id;
        },
        breadcrumbItems() {
            let items = [
                {
                    text: 'Report',
                    to: { name: 'native-report-list' },
                },
                {
                    text: `${this.item ? this.item.subject : this.itemId}`, active: true
                },
            ]
            return items
        },
    },
    created(){
        this.getGroup();
    },
    methods: {
        async getGroup(){
            let response = await service.get({id: this.itemId});
            this.item = response.document;
            this.exam_records = response.exam_records;
        },
        async getExamRecords(){
            return {list: this.exam_records}
        }
    }
}
</script>