import api from '@/libs/axios'


export default {
  async getReport(params) {
    let response = await api.get('/admin/native-talks/report', {
      params,
    })
    return response.data.data
  },
  async get(params) {
    let response = await api.get('/admin/native-talks/report-detail', {
      params,
    })
    return response.data.data ? {document: response.data.data.document, exam_records: response.data.data.exam_records} : null
  },
  async getHostList(){
    let response = await api.get('/admin/native-talks/host-list');
    return response.data.data || null;
  },
  async getCommentReport(params){
    let response = await api.get('/admin/native-talks/comment-report', { params });
    return response.data.data || null;
  }
}
